import React from "react";
import { LocalVideoTrack } from "twilio-video";
import VideoTrack from "../VideoTrack/VideoTrack";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";

import SorryPage from "../SorryPage/SorryPage";

export default function LocalVideoPreview() {
  const { localTracks } = useVideoContext();

  const videoTrack = localTracks.find(track =>
    track.name.includes("camera")
  ) as LocalVideoTrack;

  //return videoTrack ? <VideoTrack track={videoTrack} isLocal /> : <CircularProgress className={classes.loadingSpinner} /> ;
  return videoTrack ? <VideoTrack track={videoTrack} isLocal /> : <SorryPage />;
}
