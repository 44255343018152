import React, { FormEvent } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import { useAppState } from "../../state";
import useRoomState from "../../hooks/useRoomState/useRoomState";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { Typography } from "@material-ui/core";



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      alignItems: "center"
    },
    li: {
      fontSize: "9pt"
    },
    toolbar: {
      width: "100%",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
      }
    },
    rightButtonContainer: {
      display: "flex",
      alignItems: "center",
      marginLeft: "auto"
    },
    form: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
      }
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      maxWidth: 230
    },
    loadingSpinner: {
      marginLeft: "1em"
    },
    displayName: {
      margin: "1.1em 0.6em",
      minWidth: "180px",
      fontWeight: 400
    },
    joinButton: {
      margin: "1em"
    },
    quitButton: {
      margin: "1em",
      color: "black",
      backgroundColor: "white",
    },
    readButton: {
      margin: "1em"
    }
  })
);

export default function MenuBar() {
  const classes = useStyles();

  const { user, isFetching } = useAppState();
  const { isConnecting, connect, localTracks } = useVideoContext();
  const roomState = useRoomState();
/*   const search = window.location.search; 

  const params = new URLSearchParams(search);
  const strFirst = params.get('roomName')?.split("_")[0].toString() || "Unknown_User"; 
  const strRoom = params.get('roomName')?.toString() || "Empty Room";
  const strID = params.get('identity')?.toString() || String(document.referrer.toLowerCase); 
  if (strRoom) {
    window.sessionStorage.setItem('roomName',strRoom);
    window.sessionStorage.setItem('identity',strID);
  }; */
  const strRoom = window.sessionStorage.getItem("roomName") || "no_name";
  const strFirst = strRoom.split("_")[0] || "no_id";
  const strID = window.sessionStorage.getItem("identity") || "no_id";
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
      connect(newToken(strRoom,strID))
  };

  return (
    <AppBar className={classes.container} position="static">
      <Toolbar className={classes.toolbar}>
        {roomState === "disconnected" ? (
          <form className={classes.form} onSubmit={handleSubmit}>
            {window.location.search.includes("roomName=true") ||
            !user?.displayName ? (
              <TextField
                id="menu-name"
                type="hidden"
                label=""
                className={classes.textField}
                value={strRoom}
                margin="dense"
                disabled={true}
              />
            ) : (
              <Typography className={classes.displayName} variant="body1">
                {user.displayName}
              </Typography>
            )}
            <Typography className={classes.li} variant="caption">
              <ul>
               
               <strong><h3>{strFirst}, please move to a quiet environment and face your camera.</h3></strong>
                <li>
                  You will have three minutes of recording time to film yourself
                  reading the waiver.{" "}
                </li>
                <li>The average time required is one to two minutes.</li>
                <li>
                  When you reach the end, or if you cannot finish, use the red
                  stop button.{" "}
                </li>
              </ul>
            </Typography>
            <Button
              className={classes.joinButton}
              name="joiner"
              type="submit"
              color="primary"
              variant="contained"
              disabled={localTracks.length === 0}
            >
              Start Recording
            </Button>
           
            {(isConnecting || isFetching) && (
              <CircularProgress className={classes.loadingSpinner} />
            )}
          </form>
        ) : (
          <Toolbar className={classes.toolbar}>
            <caption>Now Recording. </caption>
           
          </Toolbar>
        )}
        <div className={classes.rightButtonContainer}>
        </div>
      </Toolbar>
    </AppBar>
  );

  function newToken(room: string | undefined,id: string | undefined) {
    const AccessToken = require('twilio').jwt.AccessToken;
    const VideoGrant = AccessToken.VideoGrant;

    // Substitute your Twilio AccountSid and ApiKey details
    const ACCOUNT_SID = 'AC24a67477cf602cf5bbb7bd7c0b592314';
    const API_KEY_SID = 'SK63f1069b50edada9208eb6b58ea32369';
    const API_KEY_SECRET = 'cPRxKpHRn4irXYDw5GagtoBPH2jAOY8J';

    // Create an Access Token
      const accessToken = new AccessToken(
      ACCOUNT_SID,
      API_KEY_SID,
      API_KEY_SECRET
    );

    // Set the Identity of this token
    if (id) {accessToken.identity = id} else{accessToken.identity = "undefined"};

    // Grant access to Video
    var grant = new VideoGrant();
    if (room) {grant.room = room} else {grant.room = "undefined"};
    accessToken.addGrant(grant);

    // Serialize the token as a JWT
    var jwt = accessToken.toJwt();
    return jwt;



      }
}
