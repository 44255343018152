import React from "react";
import Fab from "@material-ui/core/Fab";
import CallEnd from "@material-ui/icons/Stop";
import { styled } from "@material-ui/core/styles";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import useSelectedParticipant from "../VideoProvider/useSelectedParticipant/useSelectedParticipant";
import Participant from "../Participant/Participant";
import  Countdown3 from '../MenuBar/Countdown3/Countdown3';
//import { Redirect } from "react-router-dom";

const Container = styled("div")(({ theme }) => ({
  padding: "1em",
  width: '90px',
  height: "100%",
  gridArea: "ParticipantStrip",
  backgroundColor: "purple",
  display: "inline",
  alignContent: "center",
  [theme.breakpoints.down("xs")]: {
    padding: "1em"
  }
}));

export default function ParticipantStrip() {
  const {
    room: { localParticipant }
  } = useVideoContext();
  
  const [
    selectedParticipant,
    setSelectedParticipant
  ] = useSelectedParticipant();
  
  const { room } = useVideoContext();
  
  function fullStop() {
    
    //
   
    
    const referrrr = document.referrer 
    referrrr.replace("/waiversigned","/videoland")
    console.log(referrrr) ;
    if (referrrr.indexOf("?") > 0) {
      //window.location.href=referrrr + "&return=1"
      window.open(referrrr + "&return=1","_self","true")
    } else {
      //window.location.href=referrrr + "?return=1"
      window.open(referrrr + "?return=1","_self","true")
    }
    const x = room.name;
    console.log(x)

  }

  return (
    <Container>
      <Participant
        participant={localParticipant}
        isSelected={selectedParticipant === localParticipant}
        onClick={() => setSelectedParticipant(localParticipant)}
      /><br/><br/><br/><br/>
      <p  onClick={() => {fullStop();}}>
      stop
      <Fab  color="primary">
        <CallEnd />
      </Fab>
      </p>
      <p>Remaining: <Countdown3 fullStop = {fullStop}  />
      </p>
    </Container>
  );
}
