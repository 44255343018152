import React, { useState } from 'react';
import {  Tab, Tabs, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import WaiverPrompter from "../WaiverPrompter/WaiverPrompter"
import LocalVideoPreview from "../LocalVideoPreview/LocalVideoPreview"
//import ConnectionOptions from '../ConnectionOptions/ConnectionOptions';
//import { DeviceSelector } from '../DeviceSelector/DeviceSelector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
      minHeight: '400px',
      overflow: 'scroll',
      /* [theme.breakpoints.down('xs')]: {
        width: 'calc(100vw - 32px)',
      },
      '& .inputSelect': {
        width: 'calc(100% - 35px)',
      }, */
    },
    button: {
      float: 'right',
    },
    tabpanel: {
      //height: '100%',
      alignItems: 'center',
    },
    tabvid: {
      backgroundColor: "black",
      objectFit: "scale-down",
      alignItems: 'center',
    },
    tabtext: {
      backgroundColor: "black",
      alignItems: 'center',
    },
    paper: {
      [theme.breakpoints.down('xs')]: {
        margin: '16px',
      },
    },
  })
);

export default function PreMenu() {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <div className={classes.container}>
      <Tabs className={classes.tabpanel} value={selectedTab} onChange={handleChange}>
        <Tab className={classes.tabvid} label="Preview Video" />
        <Tab className={classes.tabtext} label="Preview Waiver Text" />
      </Tabs>
      <div  hidden={selectedTab !== 0} ><LocalVideoPreview /></div>
      <div  hidden={selectedTab !== 1}><WaiverPrompter  /></div>
    </div> 
  
  );
}
