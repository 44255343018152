import React from "react";
//import bgImage from '../../image/travel-landscape-01 (1).png'
import { styled } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
//import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
//const { isAcquiringLocalTracks } = useVideoContext();

const Container = styled("div")(({ theme }) => ({
  // position: 'relative',
  //hidden={!isAcquiringLocalTracks},
  //visibility: isAcquiringLocalTracks? 'hidden' : 'visible',
  width: "100%",
  display: "block",
  padding: "15px",
  verticalAlign: "middle",
  alignItems: "middle",
  gridTemplateRows: "1fr",
  gridTemplateColumns: `1fr`,
  backgroundColor: "white",
  color: "black",
  textAlign: "right",
  //backgroundImage: 'url('+ bgImage+')',
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  height: "100%",
  // overflowX: 'scroll',
  [theme.breakpoints.down("xs")]: {}
}));

export default function SorryPage() {
  return (
    <Container >
      <CircularProgress />Sorry, you don't appear to have a camera and microphone available. You can: <a href={window.location.href}>Try&nbsp;Again</a><br/>or:<br/>
      <a href={document.referrer.replace("waiversigned","videoland")}>Come&nbsp;Back&nbsp;Later</a>
    </Container>
  );
}
