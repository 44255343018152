import React from "react";
import { styled } from "@material-ui/core/styles";

//import Controls from "./components/Controls/Controls";
import PreMenu from "./components/PreMenu/PreMenu";
//import LocalVideoPreview from "./components/LocalVideoPreview/LocalVideoPreview";
//import WaiverPrompter from "./components/WaiverPrompter/WaiverPrompter";
import MenuBar from "./components/MenuBar/MenuBar";
import ReconnectingNotification from "./components/ReconnectingNotification/ReconnectingNotification";
import Room from "./components/Room/Room";

import useHeight from "./hooks/useHeight/useHeight";
import useRoomState from "./hooks/useRoomState/useRoomState";

const Container = styled("div")({
  display: "grid",
  gridTemplateRows: "auto 1fr"
});

const Main = styled("main")({
  overflow: "auto"
});

export default function App() {
  const roomState = useRoomState();

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  const search = window.location.search; 
  const params = new URLSearchParams(search);
  const strFirst = params.get('roomName')?.split("_")[0].toString() || "Unknown_User"; 
  const strRoom = params.get('roomName')?.toString() || "Empty Room";
  const strID = params.get('identity')?.toString() || String(document.referrer.toLowerCase); 
  if (strRoom) {
    window.sessionStorage.setItem('roomName',strRoom);
    window.sessionStorage.setItem('identity',strID);
  };
  return (
    <Container style={{ height }}>
      <MenuBar />
      <Main>
        {roomState === "disconnected" ? <PreMenu /> : <Room />}
        {/* {roomState === "disconnected" ? <LocalVideoPreview /> : <Room />} */}
        {/* {roomState === "disconnected" ? <WaiverPrompter /> : <Room />} */}
        {/* <Controls /> */}
      </Main>
      <ReconnectingNotification />
    </Container>
  );
}
