import React from 'react';
import Countdown, { CountdownApi } from 'react-countdown';


export default class Countdown3 extends React.Component {
  props: any;
  countdownApi: CountdownApi | null = null;
  state = { date: Date.now() + 180000 };

  componentDidMount () {
    this.countdownApi && this.countdownApi.start();
  }
  handleStartClick = (): void => {
    this.countdownApi && this.countdownApi.start();
  };

  handleUpdate = (): void => {
    this.forceUpdate();
  };
  handleComplete = (): void => {
    this.props.fullStop();
    this.forceUpdate();
  }
  setRef = (countdown: Countdown | null): void => {
    if (countdown) {
      this.countdownApi = countdown.getApi();
    }
  };

  isPaused(): boolean {
    return !!(this.countdownApi && this.countdownApi.isPaused());
  }

  isCompleted(): boolean {
    return !!(this.countdownApi && this.countdownApi.isCompleted());
  }

  render() {
    return (
      <>
        <Countdown
          key={this.state.date}
          ref={this.setRef}
          date={this.state.date}
          onMount={this.handleUpdate}
          onStart={this.handleUpdate}
          onPause={this.handleUpdate}
          onComplete={this.handleComplete}
          autoStart={false}
        />
        
      </>
    );
  }
}
 