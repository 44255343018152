import React from "react";
import { styled } from "@material-ui/core/styles";

// import textarea from '@material-ui/core/TextField';


export default function WaiverPrompter() {
  const search = window.location.search; 
  const params = new URLSearchParams(search);

  const roomName = params.get('roomName')?.toString() || "Unknown_User";  
  
  // const { identity } = useParams();
  const Container = styled("div")(({ theme }) => ({
    padding: "0.5em",
    overflowX: "auto",
    overflowY: "auto",
    gridArea: "WaiverPrompter",
    display: "flex",
    height: "100%",
    width: "100%",
    fontFamily: "sans-serif",
    fontSize: "14pt",
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      width: "100%"
    }
  }));

  return (
    <Container>
      <span>
        <p>(begin)</p>
        <p>
          I, <strong>{roomName}</strong>, have read the waiver and I do not have any questions
          about the waiver or about the risks that I am assuming by going
          paragliding and/or Hang Gliding with Flying Camp LLC (Flying Camp)
        </p>
        <p>
          I understand and agree to the terms of the waiver and will read it for
          you now.
        </p>
        <p>
          I, <strong>{roomName}</strong> am going paragliding/hang gliding and will be
          active with actions associated with Paragliding or Hang Gliding and
          learning to Paraglide or Hang Glide. I choose to do these things and I
          take full responsibility for doing so.
        </p>
        <p>
          I state that I am sober and clear in mind in choosing to be a part of
          this activity and sport.{" "}
        </p>
        <p>
          I know and understand that I could get seriously injured, paralyzed or
          killed doing Paragliding or Hang Gliding and I choose to do them of my
          own free will.{" "}
        </p>
        <p>
          I assume all risk and liability in making paragliding or hang gliding
          ultralight aviation.{" "}
        </p>
        <p>
          On behalf of myself, my family, and everyone else who might be
          impacted by my decision to go Paragliding or Hang Gliding:{" "}
        </p>
        <p>
          I expressly release Flying Camp and all of its instructors, staff,
          owner David Hanning, and all the land owners: Rick Jacob, Mike Tiffee,
          Joseph Castle, Thomas Austin, Robert Patterson, George Galloway and
          any land owner for any liabilities, causes of
          action, claims and/or demands arising out of or relating in any way to
          my Paragliding and Hang Gliding activities.{" "}
        </p>
        <p>
          I agree not to sue for any injury, death, loss, or harm that might
          occur to me or others while conducting Paragliding or Hang Gliding
          activities.{" "}
        </p>
        <p>
          This waiver and release includes all claims based on any theory of
          liability whatsoever, including negligence, strict liability, product
          liability or defect, and recklessness.
        </p>
        <p>(end)</p>
      </span>
    </Container>
  );
}
