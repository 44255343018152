import React from "react";
import ParticipantStrip from "../ParticipantStrip/ParticipantStrip";
import { styled } from "@material-ui/core/styles";
import WaiverPrompter from "../WaiverPrompter/WaiverPrompter";
// import MainParticipant from '../MainParticipant/MainParticipant';
// import Paper from '@material-ui/core/Paper';

const Container = styled("div")(({ theme }) => ({
  // position: 'relative',
  height: "100%",
  width: "100%",
  display: "flex",
  padding: "15px",
  // overflowX: 'scroll',
  gridTemplateRows: "1fr",
  //gridTemplateColumns: `auto`,
  gridTemplateColumns: `100px 1fr`,
  gridTemplateAreas: "ParticipantStrip WaiverPrompter",
  [theme.breakpoints.down("xs")]: {
    gridTemplateRows: "100px 1fr",
    gridTemplateColumns: `1fr`,
    height: "100%",
    width: "100%"
    /*     gridTemplateColumns: `2`,
    gridTemplateRows: `1`, */
    // gridGap: '6px',
  }
}));

export default function Room() {
  return (
    <Container>
      <ParticipantStrip />
      <WaiverPrompter />
    </Container>
  );
}
